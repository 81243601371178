import React from 'react'
import { Heading, Flex, Box, Container, Text, ListItem, UnorderedList, Link, ExternalLinkIcon } from '@chakra-ui/react'
import HookForm from '../components/form-wth-stats' // this was changed to form-with-stats
import Header from '../components/header'
// import LeadFile from '../downloads/Hogarth_Casting_Declaration_Part_1_2019_2nd_Foot.docx'

export default function Home() {
        const Project = 'NT1001'
        const BASECAMP_PROJECT_ID = '20475191'

        return (
                <>
                        <Header />
                        <Heading as="h1" fontSize="3xl" m="4" align="center">
                                Self Tape Submissions
                        </Heading>
                        <Container mb={4} padding={1}>
                                <Heading as="h2" fontSize="2xl" m="2" align="center" textTransform="uppercase">
                                        {Project}: Casting for Lamisil TV Commercial
                                </Heading>
                                <Box mb={8}>
                                        <Heading
                                                as="h3"
                                                fontSize="l"
                                                m="2"
                                                align="left"
                                                textTransform="uppercase"
                                                mt={8}
                                        >
                                                Filming Dates :
                                        </Heading>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem>
                                                        Tue 2nd Feb (Pre-light day) – TBC if needed for this day
                                                </ListItem>
                                                <ListItem> Wed 3rd Feb (Shoot Day 1)</ListItem>
                                                <ListItem>Thu 4th Feb (Shoot Day 2)</ListItem>
                                                <ListItem>
                                                        Fri 5th Feb (potential day for shooting key visual stills) - TBC
                                                </ListItem>
                                        </UnorderedList>
                                </Box>
                                <Box mb={8}>
                                        <Heading as="h3" fontSize="l" m="2" align="left" textTransform="uppercase">
                                                Actor Requirements:
                                        </Heading>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem>
                                                        Actor needs to have well balanced, beautifully shaped even
                                                        looking feet
                                                </ListItem>
                                                <ListItem>Feet must be pedicured (no colour/ no tattoos)</ListItem>
                                                <ListItem>
                                                        Feet must have even skin tone and look healthy - without any
                                                        skin conditions.
                                                </ListItem>
                                                <ListItem>Feet must belong to person between 25 and 35</ListItem>
                                                <ListItem>5 average-sized toes in each feet</ListItem>
                                                <ListItem>
                                                        Average feet size Male US9 -11 and Female size 5 -7{' '}
                                                </ListItem>
                                                <ListItem>
                                                        Average feet size Male US9 -11 and Female size 5 -7{' '}
                                                </ListItem>
                                        </UnorderedList>
                                </Box>
                                <Box mb={8}>
                                        <Heading as="h3" fontSize="l" m="2" align="left" textTransform="uppercase">
                                                Description for Self Tapes:
                                        </Heading>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem>Shots of feet (front, back, side, underneath)</ListItem>
                                                <ListItem>Shots of hands & arms (front, back)</ListItem>
                                                <ListItem>
                                                        Walking (back & forth in front of camera)
                                                        <ListItem ml={4}>
                                                                it would be good to see this with the camera lower to
                                                                the ground so we can see the feet
                                                        </ListItem>
                                                        <ListItem>
                                                                Sat down as if applying cream to their feet (they could
                                                                be sat on the bed or on a toilet)
                                                        </ListItem>
                                                </ListItem>
                                                <ListItem>Lying in bed, seeing feet hanging out of bed</ListItem>
                                        </UnorderedList>
                                </Box>
                                <Box mb={8}>
                                        <Heading as="h3" fontSize="l" m="2" align="left" textTransform="uppercase">
                                                Submission Requirements:
                                        </Heading>
                                        <UnorderedList spacing={1} ml={8}>
                                                <ListItem fontWeight="bold" textTransform="uppercase">
                                                        Self tape deadlines: 10am - Friday 22nd January.
                                                </ListItem>
                                                <ListItem>Please also upload a still of your feet</ListItem>
                                                <ListItem>
                                                        Please complete, sign and scan{' '}
                                                        <Link
                                                                color="red.500"
                                                                href="downloads/Hogarth_Worldwide_Fair_processing_notice_employee_data_v1.pdf"
                                                                download
                                                        >
                                                                Hogarth Worldwide Ltd Fair Processing Notice
                                                        </Link>
                                                </ListItem>
                                                <ListItem>
                                                        Please click onlink below and complete, sign and scan
                                                        appropriate Artist Declaration form.
                                                </ListItem>
                                                <ListItem>
                                                        <Link
                                                                color="red.500"
                                                                href="downloads/Hogarth_Casting_Declaration_Part_1_2019_LEAD.docx"
                                                                download
                                                        >
                                                                Lead Artist (Male)
                                                        </Link>
                                                </ListItem>
                                                <ListItem>
                                                        <Link
                                                                color="red.500"
                                                                href="downloads/Hogarth_Casting_Declaration_Part_1_2019_2nd_Foot.docx"
                                                                download
                                                        >
                                                                2nd Artist(Female)
                                                        </Link>
                                                </ListItem>
                                        </UnorderedList>
                                </Box>
                        </Container>
                        <Flex as="main" width="full" align="center" flexDirection="column" justifyContent="center">
                                <HookForm project={Project} basecampprojectid={BASECAMP_PROJECT_ID} />
                        </Flex>
                </>
        )
}
